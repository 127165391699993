export const projects = [
  {
    id: 1,
    img: "",
    link: "https://bookstorebybinaya.netlify.app/",
  },
  {
    id: 2,
    img: "",
    link: "https://moviewatchlistbybinaya.netlify.app/",
  },
  {
    id: 3,
    img: "",
    link: "https://mern-memoriesappbybinaya.netlify.app/",
  },

];

// export const skills = [
//   {
//     id: 1,
//     img: "https://photos.app.goo.gl/zzHUvcv2iDm9pRjF8"
//   },
//   {
//     id: 2,
//     img: ""
//   },
//   {
//     id: 3,
//     img: ""
//   },
//   {
//     id: 4,
//     img: ""
//   },
//   {
//     id: 5,
//     img: ""
//   },
//   {
//     id: 6,
//     img: ""
//   },
//   {
//     id: 7,
//     img: ""
//   },
//   {
//     id: 8,
//     img: ""
//   },
// ]
