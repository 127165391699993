import "./about.css";
import aboutme from "../../img/aboutme.png";

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src= {aboutme}
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-desc">
        I am a self-taught aspirant full stack developer who is driven by a desire to learn frontend and backend programming technologies. I was able to successfully develop numerous projects with the help of free contents available on youtube and udemy. I'm looking for a new challenge and interested in a junior or trainee position.
        </p>
        {/* <div className="a-award">
          <img src={Award} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">International Design Awards 2021</h4>
            <p className="a-award-desc">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
              and fugit.
            </p>
          </div>  
        </div> */}
      </div>
    </div>
  );
};

export default About;
