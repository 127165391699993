import './skills.css';
import React from 'react'
import csharp from '../../../src/img/skillsImg/csharp.png';
import html from '../../../src/img/skillsImg/html.png';
import css3 from '../../../src/img/skillsImg/css3.png';
import js from '../../../src/img/skillsImg/js.png';
import php from '../../../src/img/skillsImg/php.png';
import mongodb from '../../../src/img/skillsImg/mongodb.png';
import mysql from '../../../src/img/skillsImg/mysql.png';
import reactjs from '../../../src/img/skillsImg/reactjs.png';

const Skills = () => {
  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">Skills</h1>
        <p className="pl-desc">
          Here are some technologies that I have used while developing my projects.
        </p>
      </div>
      <div className="pl-list">
        <img src={html} alt="" height="250px" width="250px" />
        <img src={css3} alt="" height="250px" width="250px" />
        <img src={js} alt="" height="250px" width="250px" />
        <img src={php} alt="" height="250px" width="250px" />
        <img src={csharp} alt="" height="250px" width="250px" />
        <img src={mysql} alt="" height="250px" width="250px" />
        <img src={mongodb} alt="" height="250px" width="250px" />
        <img src={reactjs} alt="" height="250px" width="270px" />
      </div>
    </div>
  )
}

export default Skills